'use strict';

//------------------------------------------------------------------------
//タブ切り替え時のGoogle表示切替------------------------------------------
//------------------------------------------------------------------------

(function(){
const osaka = document.getElementById("osaka");
const kyoto = document.getElementById("kyoto");
const osakaMap = document.getElementById("osaka_google");
const kyotoMap = document.getElementById("kyoto_google");
	osaka.addEventListener("click",function(){
		 osakaMap.classList.add("map_block");
		 kyotoMap.classList.remove("map_block");
	});
	kyoto.addEventListener("click",function(){
		 osakaMap.classList.remove("map_block");
		 kyotoMap.classList.add("map_block");
	});

}());



var mySwiper = new Swiper ('.swiper-container', {
	loop: true,
	slidesPerView: 3,
  	spaceBetween: 90,
  	//centeredSlides : true,
	navigation: {
      nextEl: '.swiper-button-next', // 次のスライドボタンのセレクタ
      prevEl: '.swiper-button-prev', // 前のスライドボタンのセレクタ
    },
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween:40
		},
		767: {
			slidesPerView: 1,
			spaceBetween: 20
		}
	}
}) 
var mySwiper02 = new Swiper ('.swiper-container02', {
	loop: true,
	slidesPerView: 1,
  	spaceBetween: 30,
	navigation: {
      nextEl: '.swiper-button-next02', // 次のスライドボタンのセレクタ
      prevEl: '.swiper-button-prev02', // 前のスライドボタンのセレクタ
    },

}) 

var kyotoElm =  document.getElementById('kyoto');
	kyotoElm.addEventListener("change",function(){
		var mySwiper03 = new Swiper ('.swiper-container03', {
		loop: true,
		slidesPerView: 1,
		spaceBetween: 30,
		navigation: {
		  nextEl: '.swiper-button-next03', // 次のスライドボタンのセレクタ
		  prevEl: '.swiper-button-prev03', // 前のスライドボタンのセレクタ
		}
	}) 
})
